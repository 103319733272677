<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-25 pb-10">
         <div class="container">
            <div class="breadcrumb__wrapper d-flex align-items-center">
               <nav class="col" aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item"><router-link to="/produtos">Produtos</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</li>
                  </ol>
               </nav>
               <img :src="produto.fotoMarca == null ? '' : produto.fotoMarca" class="brand__image d-sm-block" :alt="produto.nomeMarca">
            </div>
         </div>
      </section>

      <!-- product area start -->
      <section class="product__area pt-10 pb-30">
         <div class="container">
            <div class="row">
               <div class="col-xxl-5 col-xl-5 col-lg-6">
                  <div class="product__details-nav d-sm-flex">
                     <ul class="nav nav-tabs flex-sm-column align-self-center mb-1" id="productThumbTab" role="tablist">
                        <foto v-for="(foto, index) in variacao.fotos" :key="'HEADER'+ index" :index="index" :foto="foto" :location="'HEADER'" :container="'PRODUTO'" />

                        <li v-if="variacao.fotos == null || variacao.fotos.length == 0" class="nav-item" role="presentation">
                           <button class="nav-link active ratio ratio-1x1" id="thumbOne-tab" data-bs-toggle="tab" type="button" role="tab" aria-selected="true">
                              <img src="#" @error="imageError">
                           </button>
                        </li>
                     </ul>
                     <div class="product__details-thumb ps-sm-1 w-100 mb-1 align-self-center">
                        <div class="tab-content" id="productThumbContent">
                           <foto v-for="(foto, index) in variacao.fotos" :key="'BODY'+ index" :index="index" :foto="foto" :location="'BODY'" :container="'PRODUTO'" 
                              @visualizar="verMidia($event)" /> 

                           <div v-if="variacao.fotos == null || variacao.fotos.length == 0" class="tab-pane fade show active" role="tabpanel">
                              <div class="product__details-nav-thumb ratio ratio-1x1"><img src="#" @error="imageError"></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-xxl-7 col-xl-7 col-lg-6">
                  <div class="product__details-wrapper">
                     <div class="product__details">
                        <h3 class="product__details-title mb-2"><a href="javascript:;">{{ produto.nome }}</a></h3>
                        <div class="product__review d-sm-flex mb-15">
                           <div class="rating rating__shop">
                              <ul>
                                 <li class="me-3"><a :href="'https://www.facebook.com/sharer/sharer.php?u='+ url" target="_blank"><i class="fab fa-facebook-square text-facebook font-20"></i></a></li>
                                 <li class="me-3"><a :href="'https://wa.me/?text='+ url" target="_blank"><i class="fab fa-whatsapp-square text-success font-20"></i></a></li>
                                 <li><a :href="'mailto:?subject='+ produto.nome +' em CCM do Brasil &amp;body=Saiba mais em nosso site '+ url" target="_blank"><i class="fas fa-envelope font-20"></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div class="product__price mb-20" v-if="logado.cliente && produto.variacoes.length == 0 && produto.preco != null">
                           <span class="new text-danger mb-0"><small>R$ </small>{{ produto.preco == null ? '-' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                        </div>
                        <div class="product__details-des mb-25" v-if="!logado.cliente">
                           <router-link to="/encontreRevenda" class="ribbon">
                              <span class="text"><i class="far fa-map-marker-alt font-12 me-1"></i> Localize a revenda mais próxima</span>
                           </router-link>
                        </div>
                        <div class="product__details-des mb-25" v-if="produto.descricao != null">
                           <p>{{ produto.descricao }}</p>
                        </div>
                        <div class="product__details-quantity d-flex align-items-center" v-if="logado.cliente && produto.variacoes.length == 0 && produto.preco != null">
                           <div class="w-max-content">
                              <div class="input-group input-quantity">
                                 <button name="btn-minus" @click.stop="produto.quantidade = produto.quantidade - produto.qtdMult;" :disabled="produto.quantidade <= produto.qtdMult">-</button>
                                 <input type="number" v-model="produto.quantidade" @blur="produto.quantidade = Math.ceil(produto.quantidade / produto.qtdMult) * produto.qtdMult">
                                 <button name="btn-plus" @click.stop="produto.quantidade = produto.quantidade + produto.qtdMult;">+</button>
                              </div>
                           </div>

                           <div class="w-max-content ms-4">
                              <button class="btn bg-theme text-white font-13" @click="addCarrinho(produto)">
                                 <i class="far fa-shopping-cart me-1"></i><span class="d-none d-sm-inline"> Adicionar ao carrinho</span>
                              </button>
                           </div>
                        </div>
                        <div class="product__details-group" v-else-if="produto.variacoes.length > 0">
                           <variacao v-for="(v, index) in produto.variacoes" :key="index" :variacao="v" :selecionado="variacao.codigo" 
                              @carrinho="addCarrinho($event)" @select="buscarVariacao($event)" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- detalhes area start -->
      <section class="detalhes__area pt-40 pb-60 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-12 mb-20">
                  <div class="product__details-des-tab">
                     <ul class="nav nav-tabs justify-content-center justify-content-md-start" id="productDesTab" role="tablist">
                        <li class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-zero" data-bs-toggle="tab" data-bs-target="#zero" type="button" role="tab" aria-controls="zero" 
                              :aria-selected="produto.divulgacao == null || String(produto.divulgacao).trim().length == 0 ? false : true" :class="produto.divulgacao == null || String(produto.divulgacao).trim().length == 0 ? '' : 'active'">
                              <i class="far fa-info-circle font-20 px-2 d-inline d-xxl-none"></i><span class="d-none d-xxl-inline">Descrição Completa</span>
                           </button>
                        </li>
                        <li class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-one" data-bs-toggle="tab" data-bs-target="#one" type="button" role="tab" aria-controls="one" 
                              :aria-selected="produto.divulgacao == null || String(produto.divulgacao).trim().length == 0 ? true : false" :class="produto.divulgacao == null || String(produto.divulgacao).trim().length == 0 ? 'active' : ''">
                              <i class="far fa-list font-20 px-2 d-inline d-xxl-none"></i><span class="d-none d-xxl-inline">Especificações Técnicas</span>
                           </button>
                        </li>
                        <li v-if="produto.apoioImpressos.length > 0 || produto.apoioMaterial.length > 0 || produto.apoioVideos.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-two" data-bs-toggle="tab" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="false">
                              <i class="far fa-file font-20 px-2 d-inline d-xxl-none"></i><span class="d-none d-xxl-inline">Materiais de apoio</span>
                           </button>
                        </li>
                        <li v-if="variacao.compativeis.length > 0" class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-three" data-bs-toggle="tab" data-bs-target="#three" type="button" role="tab" aria-controls="three" aria-selected="false">
                              <i class="far fa-retweet-alt font-20 px-2 d-inline d-xxl-none"></i><span class="d-none d-xxl-inline">Produtos compatíveis</span>
                           </button>
                        </li>
                        <li v-if="logado.cliente && variacao.vistas.length > 0" class="nav-item" role="presentation" @click="setFocusVistaExplodida">
                           <button class="nav-link" id="tab-four" data-bs-toggle="tab" data-bs-target="#four" type="button" role="tab" aria-controls="four" aria-selected="false">
                              <i class="fal fa-object-group font-20 px-2 d-inline d-xxl-none"></i><span class="d-none d-xxl-inline">Vista explodida</span>
                           </button>
                        </li>
                        <li class="nav-item" role="presentation">
                           <button class="nav-link" id="tab-five" data-bs-toggle="tab" data-bs-target="#five" type="button" role="tab" aria-controls="five" aria-selected="false">
                              <i class="far fa-not-equal font-20 px-2 d-inline d-xxl-none"></i><span class="d-none d-xxl-inline">Comparação</span>
                           </button>
                        </li>
                        <li v-if="produto.telefoneRevendedor != null" class="nav-item nav-contact d-md-block" role="presentation">
                           <a :href="'https://wa.me/55'+ String(produto.telefoneRevendedor).replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '') +'?text=Gostaria de saber mais do produto '+ url" class="btn" role="button" target="_blank">
                              <span>Dúvidas?</span>Fale com o vendedor <i class="fab fa-whatsapp"></i>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>

               <div class="col-12">
                  <div class="tab-content" id="prodductDesTaContent">
                     <!-- Divulgacao -->
                     <div class="tab-pane fade" id="zero" role="tabpanel" aria-labelledby="tab-zero" :class="produto.divulgacao.length == 0 ? '' : 'show active'">
                        <!-- <div class="row mx-auto wpx-800"> -->
                        <div class="row">
                           <div class="col-12 postagem-html" v-html="produto.divulgacao"></div>
                           <div class="col-12 px-0 mt-200 mb-200 text-center" v-if="produto.divulgacao == null || String(produto.divulgacao).trim().length == 0">Sem descrição</div>
                        </div>
                     </div>

                     <!-- Especificacoes -->
                     <div class="tab-pane fade" id="one" role="tabpanel" aria-labelledby="tab-one" :class="produto.divulgacao == null || String(produto.divulgacao).trim().length == 0 ? 'show active' : ''">
                        <div class="table-content table-responsive">
                           <table class="table border-0">
                              <tbody>
                                 <especificacao v-for="(especificacao, index) in produto.especificacoes" :key="index" :especificacao="especificacao" :index="index" :view="'Especificacoes'" />
                                 <especificacao :index="produto.especificacoes.length" :view="'Especificacoes-Baixar'" @download="downloadEspecificacoes" />
                              </tbody>
                           </table>
                        </div>
                        <div class="w-100 mt-200 mb-200 text-center" v-if="produto.especificacoes.length == 0">Sem especificação</div>
                     </div>

                     <!-- Materiais -->
                     <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="tab-two" 
                        v-if="produto.apoioImpressos.length > 0 || produto.apoioMaterial.length > 0 || produto.apoioVideos.length > 0">
                        <div class="row mb-40" v-if="produto.apoioImpressos.length > 0">
                           <div class="col-12 d-flex mb-10">
                              <div class="w-max-content pe-2 color-theme font-16"><i class="fal fa-print font-14 me-1"></i> Impressos</div>
                              <hr class="col">
                           </div>
                           <div class="col-12">
                              <div class="cards-170 cards-sm-200 cards-xl-225">
                                 <arquivo v-for="(arquivo, index) in produto.apoioImpressos" :key="'Impressos'+ index" :arquivo="arquivo" :view="'Produto-Impressos'" @download="download($event)" />
                              </div>
                           </div>
                        </div>
                        <div class="row mb-40" v-if="produto.apoioMaterial.length > 0">
                           <div class="col-12 d-flex mb-10">
                              <div class="w-max-content pe-2 color-theme font-16"><i class="fal fa-books font-14 me-1"></i> Material de apoio</div>
                              <hr class="col">
                           </div>
                           <div class="col-12">
                              <div class="cards-170 cards-sm-200 cards-xl-225">
                                 <arquivo v-for="(arquivo, index) in produto.apoioMaterial" :key="'Material'+ index" :arquivo="arquivo" :view="'Produto-Material'" />
                              </div>
                           </div>
                        </div>
                        <div class="row mb-40" v-if="produto.apoioVideos.length > 0">
                           <div class="col-12 d-flex mb-10">
                              <div class="w-max-content pe-2 color-theme font-16"><i class="fal fa-tv-music font-14 me-1"></i> Vídeos</div>
                              <hr class="col">
                           </div>
                           <div class="col-12">
                              <div class="cards-170 cards-sm-200 cards-xl-225">
                                 <arquivo v-for="(arquivo, index) in produto.apoioVideos" :key="'Videos'+ index" :arquivo="arquivo" :view="'Produto-Videos'" @visualizar="verMidia($event)" />
                              </div>
                           </div>
                        </div>
                     </div>

                     <!-- Compativeis -->
                     <div class="tab-pane fade" id="three" role="tabpanel" aria-labelledby="tab-three" v-if="variacao.compativeis.length > 0">
                        <div class="table-content table-responsive">
                           <table class="table border-0">
                              <tbody>
                                 <especificacao v-for="(especificacao, index) in variacao.compativeis" :key="index" :especificacao="especificacao" :index="index" :view="'Compativeis'" />
                              </tbody>
                           </table>
                        </div>
                     </div>
                     
                     <!-- Vista explodida -->
                     <div class="tab-pane fade" id="four" role="tabpanel" aria-labelledby="tab-four" v-if="variacao.vistas.length > 0">
                        <vistaExplodida class="mb-40" :view="'Produto'" :produto="variacao" @verMidia="verMidia($event)" @addCarrinho="addCarrinho($event)" />
                     </div>

                     <!-- Comparacao -->
                     <div class="tab-pane fade" id="five" role="tabpanel" aria-labelledby="tab-five">
                        <comparacao :codigosIniciais="Array.of(variacao.codigo)" metodo="temp" v-if="variacao.codigo != null" />
                     </div>
                  </div>
               </div>

               <div class="col-12 d-none">
                  <section ref="html2PdfEspecs">
                     <especificacoesPDF :produto="produto" :variacao="variacao" />
                  </section>
               </div>
            </div>
         </div>
      </section>

      <!-- duvidas area start -->
      <section class="duvidas__area pt-30 pb-60" v-if="produto.duvidas.length > 0">
         <div class="container">
            <div class="section__head mb-30">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Dúvidas</h3>
               </div>
            </div>
            <div class="accordion" id="accordionExample">
               <duvida v-for="(duvida, index) in produto.duvidas" :key="index" :duvida="duvida" :index="index" />
            </div>
         </div>
      </section>

      <!-- relacionados area start -->
      <relacionados :array="produto.relacionados" :titulo="'Relacionados'" :classes="'relacionados__area pb-100 grey-bg'" />

      <!-- Video -->
      <div class="modal fade" id="modalVideo" tabindex="-1" role="dialog" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-body p-0 overflow-hidden" v-if="midia.tipo == 'Vídeo'">
                  <button class="btn btn-close-modal" data-bs-dismiss="modal" aria-label="Close"><i class="far fa-times"></i></button>
                  <div class="ratio ratio-16x9" v-if="String(midia.url).startsWith('https://www.youtube.com/embed/')">
                     <iframe class="embed-responsive-item" title="video" :src="midia.url" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="ratio ratio-16x9" v-else>
                     <video class="embed-responsive-item" width="320" height="240" title="video" :src="midia.url +'#t=0.5'" controls>Your browser does not support the video.</video>
                  </div>
               </div>
               <div class="modal-body p-0 overflow-hidden" v-else>
                  <button class="btn btn-close-modal" data-bs-dismiss="modal" aria-label="Close"><i class="far fa-times"></i></button>
                  <div class="ratio ratio-16x9">
                     <img title="Foto" :src="midia.url" />
                  </div>
               </div>
            </div>
         </div> 
      </div>
   </main>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import vuePDF from 'html2pdf.js'
import foto from '@/components/produtos/Foto.vue'
import duvida from '@/components/produtos/Duvida.vue'
import arquivo from '@/components/downloads/Arquivo.vue'
import variacao from '@/components/produtos/Variacao.vue'
import relacionados from '@/components/CarouselProdutos.vue'
import comparacao from '@/components/produtos/Comparacao.vue'
import especificacao from '@/components/produtos/Especificacao.vue'
import vistaExplodida from '@/components/produtos/VistaExplodida.vue'
import especificacoesPDF from '@/components/produtos/EspecificacoesPDF.vue'

export default {
   name: 'Produto',
   data : function () {
      return {
         url: window.location.href,
         midia: {'tipo': 'Vídeo', 'url': 'https://www.youtube.com/embed/89CyW6RViuk?si=UX7CDK9zUq2H6Cya'},
         produto: {
            'codigo': '00000000', 'apoioVideos': [], 'categoria': null, 'especificacoes': [], 'nome': 'Sem nome', 'variacoes': [], 'apoioMaterial': [], 'apoioImpressos': [], 
            'descricao': 'Sem descrição', 'duvidas': [], 'nomeMarca': 'Sem marca', 'fotoMarca': null, 'relacionados': [], 'fotos': [], 'telefoneRevendedor': null, 'divulgacao': ''
         },
         variacao: {'codigo': null, 'nome': null, 'fotos': [], 'compativeis': [], 'vistas': []}
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest,
         logado: state => state.logado
		})
	},
   watch: {
      '$route.params.codigo': {
         handler: function () {
            this.getProduto()
         },
         deep: true,
         immediate: true
      }
   },
   components: {
      foto, variacao, especificacao, arquivo, relacionados, duvida, vistaExplodida, comparacao, especificacoesPDF
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      setFocusVistaExplodida : function () {
         setTimeout(() => $(".select-vista-explodida .vs__search").focus(), 500);
      },
      buscarVariacao : function (variacao) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         
			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getProdutoVariacao',
            params: {
               codigo: variacao.codigo
            }
			}).then(response => {
            this.variacao = {'codigo': variacao.codigo, 'nome': variacao.nome, 'fotos': response.data.fotos, 'compativeis': response.data.compativeis, 'vistas': response.data.vistas}
            
			}).catch((error) => {
				if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               })
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      download : function (arquivo) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: arquivo.urlDownload,
            responseType: 'blob'

         }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', arquivo.titulo);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      downloadEspecificacoes : function () {
         vuePDF(this.$refs.html2PdfEspecs, {
            margin: 0,
            filename: 'Ficha tecnica '+ this.produto.codigo +'.pdf',
            image: {'type': 'jpeg', 'quality': 1},
            pagebreak: {'avoid': 'tr'},
            html2canvas: {'letterRendering': true, 'useCORS': true},
            jsPDF: {'unit': 'in', 'format': 'letter', 'orientation': 'landscape'}
         })
      },
      verMidia : function (arquivo) {
         this.midia = {'tipo': arquivo.tipo, 'url': arquivo.tipo == 'foto' ? arquivo.url : arquivo.urlDownload}
         
         $("#modalVideo").modal("show")
         $("#modalVideo").on('hidden.bs.modal', () => {
            this.midia = {'tipo': 'Vídeo', 'url': 'https://www.youtube.com/embed/89CyW6RViuk?si=UX7CDK9zUq2H6Cya'}
         })
      },
      addCarrinho : function (produto) {
         this.$store.dispatch('addCarrinho', JSON.parse(JSON.stringify(produto)))
         this.produto.quantidade = produto.qtdMult;
      },
      getProduto : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getProduto',
            params: {
               id: this.$route.params.codigo
            }
			}).then(response => {
            this.produto = response.data
            this.variacao = {'codigo': this.produto.codigo, 'fotos': response.data.fotos, 'compativeis': response.data.compativeis, 'vistas': response.data.vistas}
            
            if (this.produto.nome != null) document.querySelector('meta[property="og:title"]').setAttribute("content", this.produto.nome);
            if (this.produto.descricao != null) document.querySelector('meta[property="og:description"]').setAttribute("content", this.produto.descricao);
            if (this.produto.fotos.length > 0) document.querySelector('meta[property="og:image"]').setAttribute("content", this.produto.fotos[0].url);

			}).catch((error) => {
				if (error.response != undefined) {
               if (error.response.status == 400) {
                  router.push('/').catch(function(){})
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
            window.scrollTo(0, 0)
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   }
}

</script>

<style scoped>

.product__details-nav-thumb {
   border: 1px solid #d6dce1;
   background-color: #fff;
   border-radius: 4px;
}

/* Tab */
#productThumbTab {
   max-width: 100vw;
   min-width: 95px;
   overflow-y: hidden;
   overflow-x: auto;
   flex-wrap: unset;
}

.product__details-group {
   max-height: none;
   overflow-y: hidden;
}

@media (min-width: 576px) {
   #productThumbTab {
      max-height: 445px;
      overflow-y: auto;
      overflow-x: hidden;
      flex-wrap: nowrap;
   }
   .product__details-group {
      max-height: 425px;
      overflow-y: auto;
      padding-right: 5px;
   }
}

#productDesTab .nav-contact {
   max-width: unset;
   flex: 1 0 0%;
   text-align: end;
   display: none;
}

#productDesTab .nav-contact a {
   line-height: 1;
   font-size: 13px;
   text-align: start;
   padding-top: 0;
   width: max-content;
}

#productDesTab .nav-contact a span {
   font-weight: 500;
   display: block;
}

/* Modal */
#modalVideo .modal-content {
   background-color: #0000 !important;
   border-color: #0000 !important;
}

#modalVideo img {
   object-fit: cover;
}

#modalVideo .modal-dialog {
	max-width: 98dvw;
	width: 130dvh;
	min-height: 98dvh;
	max-height: 98dvh;
   margin: 0;
   position: relative;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) !important;
}

#modalVideo .modal-body {
	padding: 0;
}

/* Localizar revendedor */
.ribbon {
   font-size: 14px;
   position: relative;
   display: inline-block;
   text-align: center;
   color: #fff;
   cursor: pointer;
}
.text {
   display: inline-block;
   line-height: 38px;
   background: var(--color-theme-secondary);
   position: relative;
   padding: 0 24px 0;
   font-weight: 500;
}
.text:before,.text:after {
   content:'';
   position: absolute;
   border-style: solid;
}
.text:before {
   bottom: 100%;
   left: 0;
   border-width: .5em .7em 0 0;
   border-color: transparent;
}
.text:after {
   transform: scaleX(-100%);
   top: 100%;
   left: 0;
   border-width: .5em 2em 0 0;
   border-color: color-mix(in srgb, var(--color-theme-secondary) 50%, #000) transparent transparent transparent;
}

</style>